import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ModeSwitch.module.css';
import IconMoon from '../IconMoon/IconMoon';
import IconSun from '../IconSun/IconSun';

const ModeSwitch = props => {
  const { className, rootClassName, nightMode, changeMode } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={css.toggle}>
      <input type="checkbox" id="darkmode-toggle" name="darkmode-toggle" className={css.input} onClick={changeMode} />
      <label htmlFor="darkmode-toggle" className={css.label}>
        <IconSun mode={nightMode ? "dark" : "light"} />
        <IconMoon mode={nightMode ? "light" : "dark"} />
      </label>
    </div>
  );
};
const { string } = PropTypes;
ModeSwitch.defaultProps = {
  className: null,
  rootClassName: null,
};
ModeSwitch.propTypes = {
  className: string,
  rootClassName: string,
};

export default ModeSwitch;
