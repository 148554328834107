import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Service.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import { getSrcImg } from "./GetSourceImage";

const ServiceRowColFlexComponent = (props) => {
   const { rootClassName, className, intl, nightMode, letters, intro, imageName, bgColor } = props;
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames({ [css.night]: nightMode }, css.rowFlex, classes);
   return (
      <div className={conatinerClass} style={{ backgroundColor: `${bgColor}` }}>
         <div>
            <div className={css.serviceTitle}>
               {letters.map(
                  (letter, i) => <p className={css.titleLetter} key={i}>{letter}</p>
               )}
            </div>
            {/* <p className={css.serviceIntro}>{intro}</p> */}
         </div>
         <div className={css.serviceImg}>
            <img src={getSrcImg(imageName)} alt={imageName} />
         </div>
      </div>
   );
};

ServiceRowColFlexComponent.defaultProps = { rootClassName: null, className: null };

ServiceRowColFlexComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const ServiceRowColFlex = compose(injectIntl)(ServiceRowColFlexComponent);

ServiceRowColFlex.applydisplayName = "Service";

export default React.memo(ServiceRowColFlex);


const ServiceColFlexComponent = (props) => {
   const { rootClassName, className, intl, nightMode, letters, intro, imageName, bgColor } = props;
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames({ [css.night]: nightMode }, css.colFlex, classes);
   return (
      <div className={conatinerClass} style={{ backgroundColor: `${bgColor}` }}>
         <div className={css.subFlex}>
            <div className={css.serviceTitle}>
               {letters.map((items, index) => {
                  return (
                     <div key={index}>
                        {items.map((letter, sIndex) => {
                           return (<p className={css.titleLetter} key={sIndex}>{letter}</p>);
                        })}
                     </div>
                  )
               }
               )}
            </div>
            {/* <p className={css.serviceIntro}>{intro}</p> */}
         </div>
         <div className={css.serviceImg}>
            <img src={getSrcImg(imageName)} alt={imageName} />
         </div>
      </div>
   );
};

ServiceColFlexComponent.defaultProps = { rootClassName: null, className: null };

ServiceColFlexComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

export const ServiceColFlex = compose(injectIntl)(ServiceColFlexComponent);

ServiceColFlex.applydisplayName = "Service";


