import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import IconLogo from './IconLogo';
import LogoImage from './logo.svg';
import LogoImageDark from "./logoNight.svg";
import css from "./Logo.module.css";

const LogoComponent = (props) => {
  const { className, format, src, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === "desktop") {
    return (
      <img className={className} src={src} alt={config.siteTitle} {...rest} />
    );
  }

  return <IconLogo className={mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

LogoComponent.defaultProps = {
  className: null,
  format: "desktop",
};

LogoComponent.propTypes = {
  className: string,
  format: oneOf(["desktop", "mobile"]),
};

export const DarkLogo = (props) => {
  return <LogoComponent {...props} src={LogoImageDark} />;
};
DarkLogo.displayName = "Code Dvlpr";

const Logo = (props) => {
  return <LogoComponent {...props} src={LogoImage} />;
};
Logo.displayName = "Code Dvlpr";

export default Logo;
