import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Testimonial.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import Comma from "./commas.svg";

const TestimonialComponent = (props) => {
   const { rootClassName, className, nightMode, testimonial } = props;
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames(
      { [css.night]: nightMode },
      classes
   );
   return (
      <div className={conatinerClass}>
         <div className={css.cardImg}>
            <img src={testimonial.img} alt={testimonial.name} />
            {/* https://media.istockphoto.com/photos/portrait-of-young-businessman-standing-in-his-office-with-arms-picture-id1193290387?k=20&m=1193290387&s=612x612&w=0&h=g_94MYUcCXg_gHy0enwpezhZoIUjfClKTmm_HKeSlLU= */}
         </div>
         <div className={css.testimonialContent}>
            <img src={Comma} className={css.commaImg} />
            <div className={css.CardContent}>
               <p className={css.testimonial}>{testimonial.review}</p>
               <h1 className={css.title}>{testimonial.name}</h1>
               <p className={css.designation}>{testimonial.designation}</p>
            </div>
         </div>
      </div>
   );
};

TestimonialComponent.defaultProps = { rootClassName: null, className: null };

TestimonialComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const Testimonial = compose(injectIntl)(TestimonialComponent);

Testimonial.displayName = "Testimonial";

export default React.memo(Testimonial);
