import React, { useEffect } from 'react';
import classNames from 'classnames';
import Select from "../Select/Select";
import css from './TopbarMobileMenu.module.css';
import ModeSwitch from "../ModeSwitch/ModeSwitch";
import { NamedLink, Logo, DarkLogo, IconGlobe } from '../../components';

const TopbarMobileMenu = props => {
  const {
    changeLanguage,
    nightMode,
    changeMode,
    intl,
  } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (nightMode) {
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = true)
      } else {
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = false)
      }
    }
  }, [nightMode]);

  const iconLanguage = <IconGlobe
    className={nightMode ? css.iconGlobeLight : css.iconGlobeDark}
    width={45}
  />

  const classes = classNames(css.root, { [css.night]: nightMode });
  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.actions}>
          {/* <ModeSwitch nightMode={nightMode} changeMode={changeMode} /> */}
          <NamedLink className={css.home} name="LandingPage">
            {nightMode === "" ? (
              <Logo
                format="desktop"
                width={150}
                className={css.sitelogo}
                alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
              />
            ) : (
              <DarkLogo
                format="desktop"
                width={150}
                className={css.sitelogo}
                alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
              />
            )}
          </NamedLink>
          <Select iconLanguage={iconLanguage} changeLanguage={changeLanguage} isMobile={true} intl={intl} />
        </div>
        <div className={css.links}>
          <div className={css.linksCollection}>
            <NamedLink
              className={css.link}
              name="LandingPage"
              title={intl.formatMessage({ id: "Topbar.services" })}
              pathname="/"
              to={{ hash: "#whatWeDo" }}
            >
              {intl.formatMessage({ id: "Topbar.services" })}
            </NamedLink>
            <NamedLink
              className={css.link}
              name="LandingPage"
              title={intl.formatMessage({ id: "Topbar.technologies" })}
              pathname="/"
              to={{ hash: "#Technologies" }}
            >
              {intl.formatMessage({ id: "Topbar.technologies" })}
            </NamedLink>
            {/* <NamedLink
              className={css.link}
              name="LandingPage"
              title={intl.formatMessage({ id: "Topbar.clients" })}
              pathname="/"
              to={{ hash: "#Clients" }}
            >
              {intl.formatMessage({ id: "Topbar.clients" })}
            </NamedLink> */}
            <NamedLink
              className={css.link}
              name="LandingPage"
              title={intl.formatMessage({ id: "Topbar.contactUs" })}
              pathname="/"
              to={{ hash: "#ContactUs" }}
            >
              {intl.formatMessage({ id: "Topbar.contactUs" })}
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {};

TopbarMobileMenu.propTypes = {
};

export default TopbarMobileMenu;
