import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Select.module.css';
import Select from 'react-select'
import usa from "./icons/usa.png";
import pk from "./icons/pk.png";
import fr from "./icons/fr.png";
import de from "./icons/de.png";
import es from "./icons/es.png";

const CustomSelect = props => {
  const { className, rootClassName, iconLanguage, changeLanguage, isMobile, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const handleLangChange = (e) => {
    changeLanguage(e.value);
  };
  const options = [
    { value: "en", label: "English", image: usa },
    { value: "de", label: "German", image: de },
    { value: "fr", label: "French", image: fr },
    { value: "es", label: "Spanish", image: es },
  ]
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: "none",
      background: 'none'
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0
    }),
    menu: (provided) => ({
      ...provided,
      width: 200,
      marginTop: isMobile ? 10 : 0,
      marginBottom: 0,
      right: isMobile ? 0 : 'auto',
    }),
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: 24,
        paddingRight: 24,
        borderLeft: state.data.value === intl.locale ? '3px solid #115efb' : 'none',
      }
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    })
  }

  return (
    <div className={classes}>
      <Select
        className={css.select}
        isSearchable={false}
        styles={customStyles}
        options={options}
        value={options[0]}
        formatOptionLabel={options => (
          <div className={options.label ? "spacedLabel" : "unspacedLabel"}>
            {options.image ? <img src={options.image} alt="country" /> : null}
            <span>{options.label}</span>
          </div>
        )}
        onChange={handleLangChange}
        value={{ label: iconLanguage, image: null }}
      />
    </div>
  );
};

const { string } = PropTypes;

CustomSelect.defaultProps = {
  className: null,
  rootClassName: null,
};

CustomSelect.propTypes = {
  className: string,
  rootClassName: string,
};

export default CustomSelect;
