import { BrowserRouter, StaticRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { HelmetProvider } from 'react-helmet-async';
import React from "react";
import Routes from "./Routes";
// import { IntlProvider } from "react-intl";
import { IntlProvider } from './util/reactIntl';
import routeConfiguration from "./routeConfiguration";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import messages_es from "./translations/es.json";
import messages_de from "./translations/de.json";
import messages_arab from "./translations/ur.json";
import "./styles/defaults.css";

const messages = {
  en: messages_en,
  fr: messages_fr,
  de: messages_de,
  ur: messages_arab,
  es: messages_es,
};

function App() {
  const [lang, SetLang] = React.useState("en");
  const [nightMode, SetNightMode] = React.useState("");
  const changeLang = (newLang) => {
    SetLang(newLang);
  };

  const changeMode = () => {
    SetNightMode((prevMode) => {
      prevMode === "" ?
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = true):
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = false);
      return prevMode === "" ? "night" : "";
    });
  };

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
    >
      <HelmetProvider>
        <BrowserRouter>
          <Routes
            routes={routeConfiguration(changeLang, nightMode, changeMode)}
          />
        </BrowserRouter>
      </HelmetProvider>
    </IntlProvider>
  );
}

const { any, string } = PropTypes;
export const ServerApp = props => {
  const { url, context, helmetContext } = props;
  HelmetProvider.canUseDOM = false;

  const [lang, SetLang] = React.useState("en");
  const [nightMode, SetNightMode] = React.useState("");
  const changeLang = (newLang) => {
    SetLang(newLang);
  };

  const changeMode = () => {
    SetNightMode((prevMode) => {
      prevMode === "" ?
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = true):
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = false);
      return prevMode === "" ? "night" : "";
    });
  };

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      textComponent="span"
    >
      <HelmetProvider context={helmetContext}>
        <StaticRouter location={url} context={context}>
          <Routes routes={routeConfiguration(changeLang, nightMode, changeMode)} />
        </StaticRouter>
      </HelmetProvider>
    </IntlProvider>
  )
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };


/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  serverContext,
  collectChunks
) => {
  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      helmetContext={helmetContext}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};

export default App;
