import QA from "./qa.png"
import Dev from "./dev.png"
import UiUx from "./uiUx.png"
import AppDev from "./appDev.png"


export const getSrcImg = (imageName) => {
   switch (imageName) {
      case "QA":
         return QA;
      case "Dev":
         return Dev;
      case "UiUx":
         return UiUx;
      case "AppDev":
         return AppDev;
      default:
         return null;
   }
}
