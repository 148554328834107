import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import pickBy from 'lodash/pickBy';
import {
  Button,
  Logo,
  DarkLogo,
  Modal,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  IconGlobe
} from '../../components';
import MenuIcon from './MenuIcon';
import Select from "../Select/Select";
import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      viewport,
      language,
      changeLanguage,
      nightMode,
      changeMode,
      intl,
      location,
      onManageDisableScrolling,
    } = this.props;

    const { mobilemenu } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const mobileMenu = < TopbarMobileMenu
      language={language}
      changeLanguage={changeLanguage}
      nightMode={nightMode}
      changeMode={changeMode}
      intl={intl}
    />;
    const classes = classNames(rootClassName || css.root, className);
    const containerClass = classNames({ [css.night]: nightMode }, classes);
    const iconLanguage = <IconGlobe
      className={nightMode ? css.iconGlobeLight : css.iconGlobeDark}
      width={30}
    />

    return (
      <div className={containerClass}>
        <div></div> {/* SSR Fix*/}
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
          </Button>
          <NamedLink className={css.home} name="LandingPage">
            {nightMode === "" ? (
              <Logo
                format="desktop"
                width={130}
                className={css.sitelogo}
                alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
              />
            ) : (
              <DarkLogo
                format="desktop"
                width={130}
                className={css.sitelogo}
                alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
              />
            )}
          </NamedLink>
          <Select iconLanguage={iconLanguage} changeLanguage={changeLanguage} isMobile={true} intl={intl}/>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            language={language}
            changeLanguage={changeLanguage}
            nightMode={nightMode}
            changeMode={changeMode}
            intl={intl}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          nightMode={nightMode}
          lightCloseButton={!!nightMode}
        >
          {mobileMenu}
        </Modal>
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl,
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
