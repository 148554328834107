import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconMoon.module.css';

const IconMoon = props => {
	const { className, rootClassName, mode, size } = props;
	const classes = classNames(rootClassName || css.root, className, css.moon);
	const modeClass = classNames(classes, mode == "light" ? css.light : css.dark);
	return (
		<svg className={modeClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			viewBox="0 0 206.622 206.622" enableBackground="new 0 0 206.622 206.622">
			<g>
				<g>
					<g>
						<path d="M155.287,58.38c-6.703-7.189-14.65-12.775-23.62-16.603c-1.299-0.552-2.8-0.291-3.83,0.672
							c-1.034,0.963-1.4,2.443-0.94,3.777c8.128,23.444,1.654,49.524-16.485,66.441c-18.146,16.918-44.614,21.557-67.43,11.812
							c-1.288-0.55-2.8-0.291-3.83,0.672c-1.034,0.963-1.4,2.443-0.94,3.777c3.197,9.218,8.215,17.535,14.918,24.72
							c12.721,13.643,29.996,21.517,48.64,22.168c0.836,0.03,1.668,0.043,2.5,0.043c17.728,0,34.54-6.611,47.571-18.764
							c13.647-12.724,21.517-29.998,22.168-48.643C174.658,89.807,168.011,72.025,155.287,58.38z M166.883,108.203
							c-0.585,16.74-7.655,32.251-19.905,43.675c-12.25,11.423-28.223,17.425-44.959,16.809c-16.743-0.583-32.252-7.652-43.674-19.904
							c-4.21-4.511-7.678-9.519-10.363-14.947c23.47,6.872,49.127,0.987,67.291-15.952c18.167-16.941,25.828-42.13,20.608-66.02
							c5.23,3.057,9.984,6.867,14.19,11.382C161.496,75.496,167.465,91.464,166.883,108.203z"/>
						<path d="M167.607,163.757c-1.393-1.393-3.649-1.393-5.042,0s-1.393,3.649,0,5.042l7.563,7.563
							c0.696,0.696,1.609,1.045,2.521,1.045c0.912,0,1.825-0.348,2.521-1.045c1.393-1.393,1.393-3.649,0-5.042L167.607,163.757z"/>
						<path d="M170.128,30.259l-7.563,7.565c-1.393,1.393-1.393,3.651,0,5.042c0.696,0.696,1.609,1.045,2.521,1.045
							s1.825-0.348,2.521-1.045l7.563-7.565c1.393-1.393,1.393-3.651,0-5.042C173.777,28.866,171.521,28.866,170.128,30.259z"/>
						<path d="M103.311,188.793c-1.971,0-3.566,1.597-3.566,3.566v10.697c0,1.969,1.595,3.566,3.566,3.566
							c1.971,0,3.566-1.597,3.566-3.566v-10.697C106.877,190.39,105.282,188.793,103.311,188.793z"/>
						<path d="M201.371,99.745h-10.697c-1.971,0-3.566,1.597-3.566,3.566c0,1.969,1.595,3.566,3.566,3.566h10.697
							c1.971,0,3.566-1.597,3.566-3.566C204.937,101.342,203.341,99.745,201.371,99.745z"/>
						<path d="M39.015,42.866c0.696,0.696,1.609,1.045,2.521,1.045s1.825-0.348,2.521-1.045c1.393-1.391,1.393-3.649,0-5.042
							l-7.563-7.565c-1.393-1.393-3.649-1.393-5.042,0c-1.393,1.391-1.393,3.649,0,5.042L39.015,42.866z"/>
						<path d="M39.015,163.757l-7.563,7.563c-1.393,1.393-1.393,3.649,0,5.042c0.696,0.696,1.609,1.045,2.521,1.045
							c0.912,0,1.825-0.348,2.521-1.045l7.563-7.563c1.393-1.393,1.393-3.649,0-5.042C42.664,162.364,40.408,162.364,39.015,163.757z"
						/>
						<path d="M103.311,17.829c1.971,0,3.566-1.597,3.566-3.566V3.566c0-1.969-1.595-3.566-3.566-3.566
							c-1.971,0-3.566,1.597-3.566,3.566v10.697C99.745,16.232,101.34,17.829,103.311,17.829z"/>
						<path d="M15.949,99.745H5.251c-1.971,0-3.566,1.597-3.566,3.566c0,1.969,1.595,3.566,3.566,3.566h10.697
							c1.971,0,3.566-1.597,3.566-3.566C19.514,101.342,17.919,99.745,15.949,99.745z"/>
					</g>
				</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</svg>
	);
};

const { string } = PropTypes;

IconMoon.defaultProps = {
	className: null,
	rootClassName: null,
};

IconMoon.propTypes = {
	className: string,
	rootClassName: string,
};

export default IconMoon;

