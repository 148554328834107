import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Strategy.module.css";
import StrategyImage from "./strategy.jpg";
import { intlShape, injectIntl } from "../../util/reactIntl";

const StrategyComponent = (props) => {
  const { rootClassName, className, intl, nightMode } = props;
  const mainTitle = intl.formatMessage({
    id: "Strategy.mainTitle",
  });
  const subTitle = intl.formatMessage({ id: "Strategy.subTitle" });
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames(
    { [css.night]: nightMode },
    css.strategyContent
  );
  return (
    <div className={classes}>
      <div className={conatinerClass}>
        <div className={css.leftSection}>
          <img src={StrategyImage} />
        </div>
        <div className={css.rightSection}>
          <h1>{mainTitle}</h1>
          <p>{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

StrategyComponent.defaultProps = { rootClassName: null, className: null };

StrategyComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Strategy = compose(injectIntl)(StrategyComponent);

Strategy.displayName = "Strategy";

export default React.memo(Strategy);
