import React, { Component } from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { intlShape, injectIntl } from "../../util/reactIntl";
import css from "./LetsTalk.module.css";
import { Form, Field } from "react-final-form";
import axios from "axios";
import LetsTalkAnim from "./letsTalkAnimation.gif"
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from '../../components';
import './react-phone-number-input.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import validator from "validator";

const DELAY = 1500;

class LetsTalkComponent extends Component {
   constructor(props, ...args) {
      super(props, ...args);
      this.state = {
         callback: "not fired",
         value: "[empty]",
         load: false,
         expired: "false",
         contact: {
            contactInProgress: false,
            contactError: null,
            contact: null,
         }
      };
      this._reCaptchaRef = React.createRef();
   }

   componentDidMount() {
      setTimeout(() => {
         this.setState({ load: true });
      }, DELAY);
   }

   handleChange = value => {
      this.setState({ value });
      // if value is null recaptcha expired
      if (value === null) this.setState({ expired: "true" });
   };

   asyncScriptOnLoad = () => {
      this.setState({ callback: "called!" });
   };
   
   onSubmit = async (values) => {
      if (this.state.value === null || this.state.value === "[empty]") {
         alert("Please verify that you are not a robot");
         return;
      }
      const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
      const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;
      let serverUrl = window.location.origin;
      if (useDevApiServer) {
         serverUrl = `http://localhost:${port}`;
      }
      this.setState({
         contact: { ...this.state.contact, contactInProgress: true, contactError: null }
      })
      const headers = {
         'Content-Type': 'application/json',
       };
      axios
         .post(`${serverUrl}/api/new-contact`, {
            name: values.name,
            phone: values.phonenumber,
            email: values.email,
            description: values.message,
            service: values.service,
            budget: values.budget,
            nda: !!values.nda,
            privacyPolicy: !!values.privacyPolicy,
         }, headers)
         .then(response => {
            this.setState({
               contact: { ...this.state.contact, contactInProgress: false, contact: response }
            })
         })
         .catch(error => {
            this.setState({
               contact: { ...this.state.contact, contactInProgress: false, contactError: error }
            })
         });
   };

   render() {
      const { rootClassName, className, intl, nightMode } = this.props;
      const classes = classNames({ [css.night]: nightMode }, rootClassName || css.root, className);
      const { load } = this.state || {};
      const mainTitle = intl.formatMessage({ id: "LetsTalk.mainTitle" });
      const namePlaceHolder = intl.formatMessage({ id: "LetsTalk.namePlaceHolder" });
      const emailPlaceHolder = intl.formatMessage({ id: "LetsTalk.emailPlaceHolder" });
      const phoneNumberPlaceHolder = intl.formatMessage({ id: "LetsTalk.phoneNumberPlaceHolder" });
      const descriptionPlaceHolder = intl.formatMessage({ id: "LetsTalk.descriptionPlaceHolder" });
      const budget = intl.formatMessage({ id: "LetsTalk.budget" });
      const name = intl.formatMessage({ id: "LetsTalk.name" });
      const emailAddress = intl.formatMessage({ id: "LetsTalk.emailAddress" });
      const phoneNumber = intl.formatMessage({ id: "LetsTalk.phoneNumber" });
      const projectInfo = intl.formatMessage({ id: "LetsTalk.projectInfo" });
      const privacyPolicy = intl.formatMessage({ id: "LetsTalk.privacyPolicy" });
      const nda = intl.formatMessage({ id: "LetsTalk.nda" });
      const freeEstimate = intl.formatMessage({ id: "LetsTalk.freeEstimate" });
      const service = intl.formatMessage({ id: "LetsTalk.service" });
      const Development = intl.formatMessage({ id: "LetsTalk.Development" });
      const web = intl.formatMessage({ id: "LetsTalk.Web" });
      const uiUx = intl.formatMessage({ id: "LetsTalk.uiUx" });
      const mobile = intl.formatMessage({ id: "LetsTalk.mobile" });
      const qualityAssurance = intl.formatMessage({ id: "LetsTalk.qualityAssurance" });
      const devOps = intl.formatMessage({ id: "LetsTalk.devOps" });
      const errorMessage = this.state.contact.contactError ? (
         <p className={css.error}>
            {intl.formatMessage({ id: "LetsTalk.errorContacting" })}
         </p>
      ) : null;
      const nameErr = intl.formatMessage({ id: "LetsTalk.nameErr" });
      const phoneErr = intl.formatMessage({ id: "LetsTalk.phoneErr" });
      const msgErr = intl.formatMessage({ id: "LetsTalk.msgErr" });
      const emailErr = intl.formatMessage({ id: "LetsTalk.emailErr" });
      const phoneValid = intl.formatMessage({ id: "LetsTalk.phoneValid" });
      const emailVlaid = intl.formatMessage({ id: "LetsTalk.emailVlaid" });
      const nameRequired = value => (value ? undefined : nameErr);
      const emailRequired = value => (value ? undefined : emailErr);
      const phoneNumberRequired = value => (value ? undefined : phoneErr);
      const messageRequired = value => (value ? undefined : msgErr);
      const isvalidPhoneNum = value => (isValidPhoneNumber(value) ? undefined : phoneValid);
      const isValidEmail = value => (validator.isEmail(value) ? undefined : emailVlaid);
      const composeValidators = (...validators) => value =>
         validators.reduce((error, validator) => error || validator(value), undefined);
      return (
         <div className={classes} id="ContactUs">
            <div className={css.letsTalkContent}>
               <div className={css.letsTalkHead}>
                  <h1>{mainTitle}</h1>
                  <img src={LetsTalkAnim} alt="lets-talk" />
               </div>
               <div className={css.form}>
                  <Form
                     onSubmit={this.onSubmit}
                     render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                           <div className={css.row}>
                              <Field name="name" validate={nameRequired}>
                                 {({ input, meta }) => (
                                    <div>
                                       <label>{name}</label>
                                       <input {...input} type="text" placeholder={namePlaceHolder} />
                                       {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                 )}
                              </Field>
                           </div>
                           <div className={css.row}>
                              <Field name="email" validate={composeValidators(emailRequired, isValidEmail)}>
                                 {({ input, meta }) => (
                                    <div>
                                       <label>{emailAddress}</label>
                                       <input {...input} type="text" placeholder={emailPlaceHolder} />
                                       {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                 )}
                              </Field>
                           </div>
                           <div className={css.row}>
                              <Field name="phonenumber" validate={composeValidators(phoneNumberRequired, isvalidPhoneNum)}>
                                 {({ input, meta }) => (
                                    <div className={css.phoneSelect}>
                                       <label>{phoneNumber}</label>
                                       <PhoneInput {...input} placeholder={phoneNumberPlaceHolder} />
                                       {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                 )}
                              </Field>
                           </div>
                           <div className={css.row}>
                              <label>{service}</label>
                              <Field name="service" component="select" defaultValue="1" className={css.select}>
                                 <option value="1" >{Development}</option>
                                 <option value="2">{web}</option>
                                 <option value="3">{mobile}</option>
                                 <option value="4">{uiUx}</option>
                                 <option value="5">{devOps}</option>
                                 <option value="6">{qualityAssurance}</option>
                              </Field>
                           </div>
                           <div className={css.row}>
                              <label>{budget}</label>
                              <Field name="budget" component="select" defaultValue="1" className={css.select}>
                                 <option value="1">$5000 - $10,000</option>
                                 <option value="2">$10,000 - $20,000</option>
                                 <option value="3">$20,000 - $50,000</option>
                                 <option value="4">$50,000 +</option>
                              </Field>
                           </div>
                           <div className={css.row}>
                              <Field name="message" validate={messageRequired}>
                                 {({ input, meta }) => (
                                    <div>
                                       <label>{projectInfo}</label>
                                       <textarea {...input} type="text" placeholder={descriptionPlaceHolder} />
                                       {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                 )}
                              </Field>
                           </div>
                           <div className={css.checkBox}>
                              <Field
                                 name="privacyPolicy"
                                 component="input"
                                 type="checkbox"
                                 value="privacyPolicy"
                                 className={css.inlineCheckbox}
                                 id="privacyPolicy"
                              />
                              <label className="custom-control-label" htmlFor="privacyPolicy">
                                 {privacyPolicy}
                              </label>
                           </div>
                           <div className={css.checkBox}>
                              <Field
                                 name="nda"
                                 component="input"
                                 type="checkbox"
                                 value="nda"
                                 className={css.inlineCheckbox}
                                 id="nda"
                              />
                              <label className="custom-control-label" htmlFor="nda">
                                 {nda}
                              </label>
                           </div>
                           <div className={css.row}>
                              <div className={css.recaptcha}>
                                 {load && (
                                    <ReCAPTCHA
                                       ref={this._reCaptchaRef}
                                       sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                       onChange={this.handleChange}
                                       asyncScriptOnLoad={this.asyncScriptOnLoad}
                                    />
                                 )}
                              </div>
                           </div>
                           <div className={css.row}>
                              {errorMessage}
                              <Button
                                 className={css.submitButton}
                                 type="submit"
                                 inProgress={this.state.contact.contactInProgress}
                                 disabled={submitting || pristine}
                                 ready={this.state.contact.contact}
                              >
                                 {freeEstimate}
                              </Button>
                           </div>
                        </form>
                     )}
                  />
               </div>
            </div>
         </div>
      );
   }
};

LetsTalkComponent.defaultProps = { rootClassName: null, className: null };

LetsTalkComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const LetsTalk = compose(injectIntl)(LetsTalkComponent);

LetsTalk.displayName = "LetsTalk";

export default React.memo(LetsTalk);
