import React from 'react';
import {
  LandingPage,
  NotFoundPage,
} from './containers';

const routeConfiguration = (changeLang=null, nightMode=null, changeMode=null) => {
  return [
    {
      path: "/",
      name: "LandingPage",
      changeLanguage: changeLang,
      nightMode: nightMode,
      changeMode: changeMode,
      component: (props) => <LandingPage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    }
  ];
};

export default routeConfiguration;
