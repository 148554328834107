import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  ExternalLink,
  IconSocialMediaLinkdin
} from '../../components';
import css from './Footer.module.css';

const renderSocialMediaLinks = (intl, nightMode) => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkdinPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkdin = intl.formatMessage({ id: 'Footer.goToLinkdin' });
  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook nightMode={nightMode} />
    </ExternalLink>
  ) : null;
  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter nightMode={nightMode} />
    </ExternalLink>
  ) : null;
  const linkdinLink = siteLinkdinPage ? (
    <ExternalLink
      key="linkToLinkdin"
      href={siteLinkdinPage}
      className={css.icon}
      title={goToLinkdin}
    >
      <IconSocialMediaLinkdin nightMode={nightMode} />
    </ExternalLink>
  ) : null;
  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram nightMode={nightMode} />
    </ExternalLink>
  ) : null;
  return [linkdinLink, twitterLink, fbLink, instragramLink].filter(v => v != null);
};

const mailLink = <ExternalLink href='mailto:contact@gitribe.com'>contact@gitribe.com</ExternalLink>

const Footer = props => {
  const { rootClassName, className, intl, nightMode } = props;
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames({ [css.night]: nightMode }, css.footerContent, classes);
  const socialMediaLinks = renderSocialMediaLinks(intl, nightMode);
  return (
    <div className={conatinerClass}>
      <div className={css.contact}>
        <h3>{intl.formatMessage({ id: 'Footer.contactUs' })}</h3>
        <div className={css.mail}>
          {mailLink}
        </div>
        <div className={css.phone}>
          PK: +92 3054000453
        </div>
      </div>
      <div className={css.copyright}>
        <div>© { new Date().getFullYear() } gitribe.</div>
        <div className={css.someLinks}>{socialMediaLinks}</div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
