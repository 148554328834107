import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import css from "./IconGlobe.module.css";

const IconGlobe = (props) => {
  const { rootClassName, className, width } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width={width}
      viewBox="-2 -2 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d='M2.252 8A8.014 8.014 0 0 0 2 10c0 .69.088 1.36.252 2H5.1a19.83 19.83 0 0 1 0-4H2.252zm.818-2h2.346c.266-1.217.65-2.307 1.121-3.214A8.035 8.035 0 0 0 3.07 6zm14.678 2H14.9a19.83 19.83 0 0 1 0 4h2.848c.164-.64.252-1.31.252-2s-.088-1.36-.252-2zm-.818-2a8.035 8.035 0 0 0-3.467-3.214c.472.907.855 1.997 1.121 3.214h2.346zM7.112 8A17.763 17.763 0 0 0 7 10c0 .685.038 1.355.112 2h5.776a17.763 17.763 0 0 0 0-4H7.112zm.358-2h5.06a10.758 10.758 0 0 0-.783-2.177C11.119 2.568 10.447 2 10 2c-.448 0-1.119.568-1.747 1.823-.315.632-.58 1.367-.783 2.177zm-4.4 8a8.035 8.035 0 0 0 3.467 3.214c-.472-.907-.855-1.997-1.121-3.214H3.07zm13.86 0h-2.346c-.266 1.217-.65 2.307-1.121 3.214A8.035 8.035 0 0 0 16.93 14zm-9.46 0c.203.81.468 1.545.783 2.177C8.881 17.432 9.553 18 10 18c.448 0 1.119-.568 1.747-1.823.315-.632.58-1.367.783-2.177H7.47zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z' />
    </svg>
  )
};

IconGlobe.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconGlobe.propTypes = { rootClassName: string, className: string };

export default IconGlobe;
