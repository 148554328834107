import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Carousel.module.css";
import Testimonial from "../Testimonial/Testimonial";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { intlShape, injectIntl } from "../../util/reactIntl";

const CarouselComponent = (props) => {
  const { rootClassName, className, nightMode, testimonials } = props;
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames(
    { [css.night]: nightMode },
    classes
  );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1921 },
      items: 3,
      slidesToSlide: 3
    },
    largeDesktop: {
      breakpoint: { max: 1921, min: 1024 },
      items: 3,
      slidesToSlide: 3
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 550 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <div className={conatinerClass}>
      <Carousel
        arrows={false}
        keyBoardControl={true}
        renderDotsOutside={false}
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
      >
        {testimonials.map((testimonial, i) => <Testimonial nightMode={nightMode} testimonial={testimonial} />)}
      </Carousel>
    </div>
  );
};

CarouselComponent.defaultProps = { rootClassName: null, className: null };

CarouselComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const CustomCarousel = compose(injectIntl)(CarouselComponent);

CustomCarousel.displayName = "Carousel";

export default React.memo(CustomCarousel);
