import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Statics.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import Project from "./Project.svg"
import Team from "./Team.svg"
import Years from "./Years.svg"

const StaticsComponent = (props) => {
  const { rootClassName, className, intl, nightMode } = props;

  const mainTitle = intl.formatMessage({
    id: "Statics.mainTitle",
  });
  const subTitle = intl.formatMessage({ id: "Statics.subTitle" });
  const serving = intl.formatMessage({ id: "Statics.serving" });
  const reaching = intl.formatMessage({ id: "Statics.reaching" });
  const success = intl.formatMessage({ id: "Statics.successfully" });
  const years = intl.formatMessage({ id: "Statics.years" });
  const projects = intl.formatMessage({ id: "Statics.projects" });
  const teams = intl.formatMessage({ id: "Statics.teams" });
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames({ [css.night]: nightMode }, css.staticsContent);

  return (
    <div className={classes}>
      <div className={conatinerClass}>
        <h1 className={css.mainTitle}>{mainTitle}</h1>
        <p className={css.subTitle}>{subTitle}</p>
        <div className={css.stats}>
          <div className={css.stat}>
            <img src={Years} />
            <p>{serving}</p>
            <p className={css.count}>7+</p>
            <p>{years}</p>
          </div>
          <div className={css.verticalDivider}></div>
          <div className={css.stat}>
            <img src={Team} />
            <p>{reaching}</p>
            <p className={css.count}>20+</p>
            <p>{teams}</p>
          </div>
          <div className={css.verticalDivider}></div>
          <div className={css.stat}>
            <img src={Project} />
            <p>{success}</p>
            <p className={css.count}>100+</p>
            <p>{projects}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

StaticsComponent.defaultProps = { rootClassName: null, className: null };

StaticsComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Statics = compose(injectIntl)(StaticsComponent);

Statics.displayName = "Statics";

export default React.memo(Statics);
