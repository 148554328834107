import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Technologies.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import TechnologiesTab from "../TechnologiesTab/TechnologiesTab";
import figma from "./icons/figma.png"
import sketch from "./icons/sketch.png"
import photoshop from "./icons/photoshop.png"
import illustrator from "./icons/illustrator.png"
import html from "./icons/html.png"
import cssIcon from "./icons/css.png"
import javascript from "./icons/js.png"
import reactjs from "./icons/react.png"
import nodejs from "./icons/nodejs.png"
import expressjs from "./icons/expressjs.png"
import angular from "./icons/angular.png"
import mongodb from "./icons/mongodb.png"
import sql from "./icons/sql.png"
import postgreSQL from "./icons/postgreSQL.png"
import rubyonrails from "./icons/rubyonrails.png"
import reactnative from "./icons/react.png"
import flutter from "./icons/flutter.png"
import swift from "./icons/swift.png"
import ionic from "./icons/ionic.png"

const TechnologiesComponent = (props) => {
   const { rootClassName, className, intl, nightMode } = props;

   const technologies = {
      uiux: [
         {
            id: figma,
            name: intl.formatMessage({ id: "Technologies.figma" }),
            img: figma,
         },
         {
            id: sketch,
            name: intl.formatMessage({ id: "Technologies.sketch" }),
            img: sketch,
         },
         {
            id: photoshop,
            name: intl.formatMessage({ id: "Technologies.photoshop" }),
            img: photoshop,
         },
         {
            id: illustrator,
            name: intl.formatMessage({ id: "Technologies.illustrator" }),
            img: illustrator,
         },
      ],
      web: [
         {
            id: html,
            name: intl.formatMessage({ id: "Technologies.html" }),
            img: html
         },
         {
            id: css,
            name: intl.formatMessage({ id: "Technologies.css" }),
            img: cssIcon
         },
         {
            id: javascript,
            name: intl.formatMessage({ id: "Technologies.javascript" }),
            img: javascript
         },
         {
            id: reactjs,
            name: intl.formatMessage({ id: "Technologies.reactjs" }),
            img: reactjs
         },
         {
            id: nodejs,
            name: intl.formatMessage({ id: "Technologies.nodejs" }),
            img: nodejs
         },
         {
            id: expressjs,
            name: intl.formatMessage({ id: "Technologies.expressjs" }),
            img: expressjs
         },
         {
            id: angular,
            name: intl.formatMessage({ id: "Technologies.angular" }),
            img: angular
         },
         {
            id: mongodb,
            name: intl.formatMessage({ id: "Technologies.mongodb" }),
            img: mongodb
         },
         {
            id: sql,
            name: intl.formatMessage({ id: "Technologies.sql" }),
            img: sql
         },
         {
            id: postgreSQL,
            name: intl.formatMessage({ id: "Technologies.postgreSQL" }),
            img: postgreSQL
         },
         {
            id: rubyonrails,
            name: intl.formatMessage({ id: "Technologies.rubyonrails" }),
            img: rubyonrails
         },
      ],
      appdev: [
         {
            id: reactnative,
            name: intl.formatMessage({ id: "Technologies.reactnative" }),
            img: reactnative
         },
         {
            id: flutter,
            name: intl.formatMessage({ id: "Technologies.flutter" }),
            img: flutter
         },
         {
            id: swift,
            name: intl.formatMessage({ id: "Technologies.swift" }),
            img: swift
         },
         {
            id: ionic,
            name: intl.formatMessage({ id: "Technologies.ionic" }),
            img: ionic
         },
      ],
   }
   const mainTitle = intl.formatMessage({ id: "Technologies.mainTitle" });
   const uiUx = intl.formatMessage({ id: "Technologies.uiUx" });
   const web = intl.formatMessage({ id: "Technologies.web" });
   const appdev = intl.formatMessage({ id: "Technologies.appdev" });
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames(
      { [css.night]: nightMode },
      css.technologiesContent
   );
   return (
      <div className={classes} id="Technologies">
         <div className={conatinerClass}>
            <h1>{mainTitle}</h1>
            <Tabs focusTabOnClick={false}>
               <TabList>
                  <Tab>{uiUx}</Tab>
                  <Tab>{web}</Tab>
                  <Tab>{appdev}</Tab>
               </TabList>
               <TabPanel><TechnologiesTab technologies={technologies.uiux} nightMode={nightMode} /></TabPanel>
               <TabPanel><TechnologiesTab technologies={technologies.web} nightMode={nightMode} /></TabPanel>
               <TabPanel><TechnologiesTab technologies={technologies.appdev} nightMode={nightMode} /></TabPanel>
            </Tabs>
         </div>
      </div>
   );
};

TechnologiesComponent.defaultProps = { rootClassName: null, className: null };

TechnologiesComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const Technologies = compose(injectIntl)(TechnologiesComponent);

Technologies.displayName = "Technologies";

export default React.memo(Technologies);
