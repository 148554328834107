import React, { Component } from 'react';
import { arrayOf, bool, object, func, shape, string } from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { NotFoundPage } from './containers';
import { NamedRedirect } from './components';
import { route } from "react-router-prop-types"
import { canonicalRoutePath } from './util/routes';
import routeConfiguration from './routeConfiguration';

const setPageScrollPosition = location => {
  if (!location.hash) {
    // No hash, scroll to top
    window.scroll({
      top: 0,
      left: 0,
    });
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      // Found element from the current page with the given fragment identifier,
      // scrolling to that element.
      //
      // NOTE: This only works on in-app navigation within the same page.
      // If smooth scrolling is needed between different pages, one needs to wait
      //   1. loadData fetch and
      //   2. code-chunk fetch
      // before making el.scrollIntoView call.

      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

const handleLocationChanged = (location) => {
  setPageScrollPosition(location);
};

/**
 * RouteComponentRenderer handles loadData calls on client-side.
 * It also checks authentication and redirects unauthenticated users
 * away from routes that are for authenticated users only
 * (aka "auth: true" is set in routeConfiguration.js)
 *
 * This component is a container: it needs to be connected to Redux.
 */
class RouteComponentRenderer extends Component {
  componentDidMount() {
    handleLocationChanged(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      handleLocationChanged(this.props.location);
    }
  }
  render() {
    const { route, match, location, staticContext } = this.props;
    const {
      component: RouteComponent,
      extraProps,
      language,
      changeLanguage,
      nightMode,
      changeMode,
    } = route;

    return (
      <RouteComponent
        params={match.params}
        location={location}
        {...extraProps}
        language={language}
        changeLanguage={changeLanguage}
        nightMode={nightMode}
        changeMode={changeMode}
      />
    );
  }
}

RouteComponentRenderer.defaultProps = { staticContext: {} };

RouteComponentRenderer.propTypes = {
  route: route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const RouteComponentContainer = RouteComponentRenderer;

/**
 * Routes component creates React Router rendering setup.
 * It needs routeConfiguration (named as "routes") through props.
 * Using that configuration it creates navigation on top of page-level
 * components. Essentially, it's something like:
 * <Switch>
 *   <Route render={pageA} />
 *   <Route render={pageB} />
 * </Switch>
 */
const Routes = (props, context) => {
  const { routes } = props;
  const toRouteComponent = (route) => {
    const renderProps = {
      route,
    };
    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={(matchProps) => (
          <RouteComponentContainer
            {...renderProps}
            match={matchProps.match}
            location={matchProps.location}
          />
        )}
      />
    );
  };

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not rerendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <Switch>
      {routes.map(toRouteComponent)}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.propTypes = {
  routes: arrayOf(route).isRequired,
};

export default withRouter(Routes);
