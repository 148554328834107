import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { matchPathname } from './util/routes';
import routeConfiguration from './routeConfiguration';
import { renderApp } from './App';

typeof window !== 'undefined' && 
ReactDOM.hydrate(
 <React.StrictMode>
     <App />
  </React.StrictMode>, 
document.getElementById("root"))

// Export the function for server side rendering.
export default renderApp;
// exporting matchPathname and configureStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has preloading needs
// configureStore is used for creating initial store state for Redux after preloading
export { matchPathname, routeConfiguration};