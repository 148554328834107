import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./SectionHero.module.css";
import anim from "./HeroSecAnim.gif";
import { intlShape, injectIntl } from "../../util/reactIntl";
import { NamedLink } from "../../components";

const SectionHeroComponent = (props) => {
  const { rootClassName, className, intl, nightMode } = props;
  const mainTitle = intl.formatMessage({
    id: "SectionHero.mainTitle",
  });
  const title = intl.formatMessage({ id: "SectionHero.title" });
  const subTitle = intl.formatMessage({
    id: "SectionHero.subTitle",
  });
  const intro = intl.formatMessage({ id: "SectionHero.intro" });
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames(classes, { [css.night]: nightMode });

  return (
    <div className={conatinerClass}>
      <div className={css.heroContent}>
        <div className={css.heroSectionLeft}>
          <h1 className={css.heroTitle}>
            {mainTitle}
            <div className={css.heroSubTitle}>{subTitle}</div>
            {title}
          </h1>
          <p className={css.sectionHeroIntro}>{intro}</p>
          <NamedLink
            className={css.sectionHeroTalkButton}
            name="LandingPage"
            title={intl.formatMessage({ id: "SectionHero.button" })}
            to={{ hash: "#ContactUs" }}
          >
            {intl.formatMessage({ id: "SectionHero.button" })}
          </NamedLink>

        </div>
        <div className={css.heroSectionRight}>
          <img
            src={anim}
            className={css.heroSectionImg}
            alt="Hero Section Animation"
          />
        </div>
      </div>
    </div>
  );
};

SectionHeroComponent.defaultProps = { rootClassName: null, className: null };

SectionHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const SectionHero = compose(injectIntl)(SectionHeroComponent);

SectionHero.displayName = "Sectionhero";

export default React.memo(SectionHero);
