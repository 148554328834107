import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Logo, DarkLogo, NamedLink, IconGlobe } from "../../components";
import css from "./TopbarDesktop.module.css";
import ModeSwitch from "../ModeSwitch/ModeSwitch";
import { intlShape } from "../../util/reactIntl";
import "./style.css";
import Select from "../Select/Select";

const TopbarDesktop = (props) => {
  const {
    className,
    rootClassName,
    changeLanguage,
    nightMode,
    changeMode,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const containerClass = classNames({ [css.night]: nightMode }, css.container);

  const iconLanguage = (
    <IconGlobe
      className={nightMode ? css.iconGlobeLight : css.iconGlobeDark}
      width={45}
    />
  );
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (nightMode) {
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = true)
      } else {
        document.getElementsByName("darkmode-toggle").forEach( x=> x.checked = false)
      }
    }
  }, [nightMode]);

  return (
    <nav className={classes}>
      <div className={containerClass}>
        <NamedLink className={css.home} name="LandingPage">
          {nightMode === "" ? (
            <Logo
              format="desktop"
              width={150}
              className={css.sitelogo}
              alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
            />
          ) : (
            <DarkLogo
              format="desktop"
              width={150}
              className={css.sitelogo}
              alt={intl.formatMessage({ id: "TopbarDesktop.logo" })}
            />
          )}
        </NamedLink>
        <div className={css.topbarContent}>
          <div className={css.links}>
            <div className={css.linksCollection}>
              <NamedLink
                className={css.link}
                name="LandingPage"
                title={intl.formatMessage({ id: "Topbar.services" })}
                pathname="/"
                to={{ hash: "#whatWeDo" }}
              >
                {intl.formatMessage({ id: "Topbar.services" })}
              </NamedLink>
              <NamedLink
                className={css.link}
                name="LandingPage"
                title={intl.formatMessage({ id: "Topbar.technologies" })}
                pathname="/"
                to={{ hash: "#Technologies" }}
              >
                {intl.formatMessage({ id: "Topbar.technologies" })}
              </NamedLink>
              {/* <NamedLink
                className={css.link}
                name="LandingPage"
                title={intl.formatMessage({ id: "Topbar.clients" })}
                pathname="/"
                to={{ hash: "#Clients" }}
              >
                {intl.formatMessage({ id: "Topbar.clients" })}
              </NamedLink> */}
              <NamedLink
                className={css.link}
                name="LandingPage"
                title={intl.formatMessage({ id: "Topbar.contactUs" })}
                pathname="/"
                to={{ hash: "#ContactUs" }}
              >
                {intl.formatMessage({ id: "Topbar.contactUs" })}
              </NamedLink>
            </div>
          </div>
          <div className={css.actions}>
            <Select
              iconLanguage={iconLanguage}
              changeLanguage={changeLanguage}
              isMobile={false}
              intl={intl}
            />
            {/* <ModeSwitch nightMode={nightMode} changeMode={changeMode} /> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

const { string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
