import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import commaLeft from "./commaLeft.png";
import commaRight from "./commaRight.png";
import commaFilledRight from "./commaFilledRight.png";
import commaFilledLeft from "./commaFilledLeft.png";
import css from "./Qoutes.module.css";

const CommaLeft = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <img src={commaLeft} className={css.qoute} alt="left qoute" />;
};

const { string } = PropTypes;

CommaLeft.defaultProps = {
  className: null,
  rootClassName: null,
};

CommaLeft.propTypes = {
  className: string,
  rootClassName: string,
};

export default CommaLeft;

export const CommaRight = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <img src={commaRight} className={css.qoute} alt="right qoute" />;
};

export const CommaLeftFilled = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <img src={commaFilledRight} className={css.qoute} alt="left qoute" />;
};

export const CommaRightFilled = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <img src={commaFilledLeft} className={css.qoute} alt="left qoute" />;
};
