import React from "react";
import classNames from "classnames";
import css from "./LandingPage.module.css";
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LetsTalk,
  Page,
  SectionHero,
  Strategy,
  Statics,
  Solution,
  WhatWeDo,
  Technologies,
  Testimonials,
  Footer,
} from "../../components";
import { TopbarContainer } from "../../containers";

import facebookImage from '../../assets/logo.svg';
import twitterImage from '../../assets/logo.svg';

class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollingDisabled: false,
    };
  }

  onManageDisableScrolling = (componentId, scrollingDisabled = false) => {
    this.setState({
      scrollingDisabled: scrollingDisabled,
    });
  };

  render() {
    const {
      history,
      location,
      changeLanguage,
      nightMode,
      changeMode,
      intl,
    } = this.props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const containerClass = classNames({ [css.night]: nightMode }, css.root);
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    return (
      <Page 
        scrollingDisabled={this.state.scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn className={containerClass}>
          <LayoutWrapperTopbar>
            <TopbarContainer
              language={intl.locale}
              changeLanguage={changeLanguage}
              nightMode={nightMode}
              changeMode={changeMode}
              onManageDisableScrolling={this.onManageDisableScrolling}
              intl={intl}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.wrapper}>
              <div className={css.container}>
                <div className={css.heroContainer}>
                  <hr className={css.divider} />
                  <SectionHero
                    className={css.hero}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                <div className={css.strategyContainer}>
                  <Strategy
                    className={css.strategy}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                <div className={css.SolutionContainer}>
                  <Solution
                    className={css.Solution}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                <div className={css.staticsContainer}>
                  <Statics
                    className={css.statics}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                <div className={css.WhatWeDoContainer}>
                  <WhatWeDo
                    className={css.WhatWeDo}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                <div className={css.TechnologiesContainer}>
                  <Technologies
                    className={css.Technologies}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
                {/* <div className={css.TestimonailsContainer}>
                  <Testimonials
                    className={css.Testimonails}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div> */}
                <div className={css.LetsTalkContainer}>
                  <LetsTalk
                    className={css.LetsTalk}
                    history={history}
                    location={location}
                    nightMode={nightMode}
                  />
                </div>
              </div>
              <div className={css.footerWrapper}>
                <Footer nightMode={nightMode} changeMode={changeMode} />
              </div>
            </div>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

LandingPageComponent.defaultProps = {};

LandingPageComponent.propTypes = {};

const LandingPage = injectIntl(LandingPageComponent);

export default LandingPage;
