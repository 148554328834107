import React from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Topbar } from '../../components';

export const TopbarContainerComponent = props => {
  const {
    history,
    location,
    onManageDisableScrolling,
    ...rest
  } = props;

  return (
    <Topbar
      history={history}
      location={location}
      onManageDisableScrolling={onManageDisableScrolling}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
};

TopbarContainerComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const TopbarContainer = compose(
  withRouter
)(TopbarContainerComponent);

export default TopbarContainer;
