import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import css from "./IconSocialMediaLinkdin.module.css";

const IconSocialMediaLinkdin = (props) => {
  const { rootClassName, className, nightMode } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="35"
      height="35"
      viewBox="0 0 11 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={nightMode ? "#fff" : "#000"}>
        <path d="M0 5h3.578v11H0zM13.324 5.129c-.038-.012-.074-.025-.114-.036a2.32 2.32 0 0 0-.145-.028A3.207 3.207 0 0 0 12.423 5c-2.086 0-3.409 1.517-3.845 2.103V5H5v11h3.578v-6s2.704-3.766 3.845-1v7H16V8.577a3.568 3.568 0 0 0-2.676-3.448z" />
        <circle cx="1.75" cy="1.75" r="1.75" />
      </g>
    </svg>
  );
};

IconSocialMediaLinkdin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkdin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkdin;
