import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./WhatWeDo.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import ServiceRowColFlex, { ServiceColFlex } from "../Service/Service";

const WhatWeDoComponent = (props) => {
   const { rootClassName, className, intl, nightMode } = props;
   const mainTitle = intl.formatMessage({
      id: "WhatWeDo.mainTitle",
   });
   const subTitle = intl.formatMessage({ id: "WhatWeDo.subTitle" });
   const serviceIntro = intl.formatMessage({ id: "WhatWeDo.serviceIntro" });
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames({ [css.night]: nightMode }, css.whatWeDoContent);

   return (
      <div className={classes} id="whatWeDo">
         <div className={conatinerClass}>
            <div className={css.titles}>
               <h1 className={css.mainTitle}>{mainTitle}</h1>
               <p className={css.subTitle}>{subTitle}</p>
            </div>
            <div className={css.services}>
               <div className={css.block}>
                  <ServiceColFlex intro={serviceIntro} letters={[['U', 'I'], ['U', 'X']]} bgColor="rgb(18, 195, 235)" imageName="UiUx" />
               </div>
               <div className={css.block} >
                  <ServiceRowColFlex intro={serviceIntro} letters={['D', 'E', 'V']} bgColor="rgb(218, 175, 245)" imageName="Dev" />
               </div>
            </div>
            <div className={css.services}>
               <div className={css.block}>
                  <ServiceColFlex intro={serviceIntro} letters={[['A', 'P', 'P'], ['D', 'E', 'V']]} bgColor="rgb(255, 242, 158)" imageName="AppDev" />
               </div>
               <div className={css.block}>
                  <ServiceRowColFlex intro={serviceIntro} letters={['Q', 'A']} bgColor="rgb(255, 186, 186)" imageName="QA" />
               </div>
            </div>
         </div>
      </div>
   );
};

WhatWeDoComponent.defaultProps = { rootClassName: null, className: null };

WhatWeDoComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const WhatWeDo = compose(injectIntl)(WhatWeDoComponent);

WhatWeDo.applydisplayName = "WhatWeDo";

export default React.memo(WhatWeDo);
