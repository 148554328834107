import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./TechnologiesTab.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";

const TechnologiesTabComponent = (props) => {
   const { rootClassName, className, nightMode, technologies } = props;
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames(
      { [css.night]: nightMode },
      css.TechnologiesContent
   );
   return (
      <div className={classes}>
         <div className={conatinerClass}>
            <div className={css.technologyIcons}>
               {technologies.map(
                  (technology, i) => <div key={i} className={css.technologyIcon}>
                                       <img src={technology.img} alt={technology.name} />
                                       <div className={css.technologyName}>{technology.name}</div>
                                    </div>
               )}
            </div>
         </div>
      </div>
   );
};

TechnologiesTabComponent.defaultProps = { rootClassName: null, className: null };

TechnologiesTabComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const TechnologiesTab = compose(injectIntl)(TechnologiesTabComponent);

TechnologiesTab.displayName = "Technologies Tab";

export default React.memo(TechnologiesTab);
