import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Solution.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import solutionImg from "./Solution.gif"
const SolutionComponent = (props) => {
   const { rootClassName, className, intl, nightMode } = props;
   const mainTitle = intl.formatMessage({
      id: "Solution.mainTitle",
   });
   const subTitle = intl.formatMessage({ id: "Solution.subTitle" });
   const classes = classNames(rootClassName || css.root, className);
   const conatinerClass = classNames({ [css.night]: nightMode }, css.solutionContent);
   return (
      <div className={classes}>
         <div className={conatinerClass}>
            <div>
               <h1 className={css.mainTitle}>{mainTitle}</h1>
               <p className={css.subTitle}>{subTitle}</p>
            </div>
            <img src={solutionImg} alt="Solution" className={css.solutionImage} />
         </div>
      </div>
   );
};

SolutionComponent.defaultProps = { rootClassName: null, className: null };

SolutionComponent.propTypes = {
   rootClassName: string,
   className: string,
   intl: intlShape.isRequired,
};

const Solution = compose(injectIntl)(SolutionComponent);

Solution.applydisplayName = "Solution";

export default React.memo(Solution);
