import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import envelope from "./envelope.png";
import css from "./Envelope.module.css";

const Envelope = (props) => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return <img src={envelope} alt="Envelope" />;
};

const { string } = PropTypes;

Envelope.defaultProps = {
  className: null,
  rootClassName: null,
};

Envelope.propTypes = {
  className: string,
  rootClassName: string,
};

export default Envelope;
